const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["remove"] = document.createElement("button","coral-button");
  el0.setAttribute("tracking", "off");
  el0.setAttribute("type", "button");
  el0.setAttribute("is", "coral-button");
  el0.setAttribute("handle", "remove");
  el0.setAttribute("variant", "quietaction");
  el0.setAttribute("icon", "delete");
  el0.setAttribute("iconsize", "S");
  el0.className += " _coral-Multifield-remove";
  el0.setAttribute("coral-multifield-remove", "");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["move"] = document.createElement("button","coral-button");
  el2.setAttribute("tracking", "off");
  el2.setAttribute("type", "button");
  el2.setAttribute("is", "coral-button");
  el2.setAttribute("handle", "move");
  el2.setAttribute("variant", "quietaction");
  el2.setAttribute("icon", "moveUpDown");
  el2.setAttribute("iconsize", "S");
  el2.className += " _coral-Multifield-move u-coral-openHand";
  el2.setAttribute("coral-multifield-move", "");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  return frag;
});
export default template;