const template = (function anonymous(data_0
) {
    var frag = document.createDocumentFragment();
    var data = data_0;
    var el0 = document.createElement("div");
    el0.className += " _coral-CoachMarkIndicator-ring";
    frag.appendChild(el0);
    var el1 = document.createTextNode("\n");
    frag.appendChild(el1);
    var el2 = document.createElement("div");
    el2.className += " _coral-CoachMarkIndicator-ring";
    frag.appendChild(el2);
    var el3 = document.createTextNode("\n");
    frag.appendChild(el3);
    var el4 = document.createElement("div");
    el4.className += " _coral-CoachMarkIndicator-ring";
    frag.appendChild(el4);
    var el5 = document.createTextNode("\n");
    frag.appendChild(el5);
    return frag;
});
export default template;