const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["overlay"] = document.createElement("coral-popover");
  el0.setAttribute("tracking", "off");
  el0.setAttribute("smart", "");
  el0.className += " _coral-Datepicker-overlay";
  el0.setAttribute("handle", "overlay");
  el0.id = data_0["commons"]["getUID"]();
  el0.setAttribute("breadthoffset", "50%r - 50%p");
  el0.setAttribute("placement", "bottom");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["hiddenInput"] = document.createElement("input");
  el2.setAttribute("type", "hidden");
  el2.setAttribute("handle", "hiddenInput");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  var el4 = this["input"] = document.createElement("input","coral-textfield");
  el4.setAttribute("tracking", "off");
  el4.setAttribute("is", "coral-textfield");
  el4.setAttribute("type", "text");
  el4.setAttribute("handle", "input");
  el4.className += " _coral-InputGroup-field";
  el4.setAttribute("role", "textbox");
  frag.appendChild(el4);
  var el5 = document.createTextNode("\n");
  frag.appendChild(el5);
  var el6 = this["toggle"] = document.createElement("button","coral-button");
  el6.setAttribute("tracking", "off");
  el6.setAttribute("is", "coral-button");
  el6.setAttribute("variant", "_custom");
  el6.className += " _coral-InputGroup-button _coral-FieldButton";
  el6.setAttribute("type", "button");
  el6.setAttribute("handle", "toggle");
  el6.setAttribute("aria-haspopup", "true");
  el6.setAttribute("aria-label", data_0["i18n"]["get"]('Calendar'));
  el6.setAttribute("title", data_0["i18n"]["get"]('Calendar'));
  var el7 = document.createTextNode("\n  ");
  el6.appendChild(el7);
  var el8 = this["icon"] = document.createElement("coral-icon");
  el8.setAttribute("icon", "calendar");
  el8.setAttribute("iconsize", "S");
  el8.className += " u-coral-noMargin";
  el8.setAttribute("handle", "icon");
  el6.appendChild(el8);
  var el9 = document.createTextNode("\n  ");
  el6.appendChild(el9);
  var el10 = document.createElement("coral-button-label");
  el6.appendChild(el10);
  var el11 = document.createTextNode("\n");
  el6.appendChild(el11);
  frag.appendChild(el6);
  var el12 = document.createTextNode("\n");
  frag.appendChild(el12);
  return frag;
});
export default template;