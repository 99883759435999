const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["container"] = document.createElement("div");
  el0.className += " _coral-DatePicker-calendarSlidingContainer";
  el0.setAttribute("handle", "container");
  el0.setAttribute("style", "width: "+data_0["width"]+"px; height: "+data_0["height"]+"px;");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;