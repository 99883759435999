const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["headerWrapper"] = document.createElement("div");
  el0.className += " _coral-Dialog-header";
  el0.setAttribute("handle", "headerWrapper");
  el0.setAttribute("role", "presentation");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["closeButton"] = document.createElement("button","coral-button");
  el2.setAttribute("tracking", "off");
  el2.setAttribute("handle", "closeButton");
  el2.className += " _coral-Dialog-closeButton _coral-ClearButton";
  el2.setAttribute("type", "button");
  el2.setAttribute("is", "coral-button");
  el2.setAttribute("variant", "_custom");
  el2.setAttribute("icon", "close");
  el2.setAttribute("title", data_0["i18n"]["get"]('Close'));
  el2.setAttribute("tabindex", "-1");
  el2.setAttribute("coral-close", "");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  var el4 = this["tip"] = document.createElement("div");
  el4.className += " _coral-Popover-tip";
  el4.setAttribute("handle", "tip");
  frag.appendChild(el4);
  var el5 = document.createTextNode("\n");
  frag.appendChild(el5);
  return frag;
});
export default template;