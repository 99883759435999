const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["shellMenuButton"] = document.createElement("button","coral-button");
  el0.setAttribute("is", "coral-button");
  el0.setAttribute("variant", "quietaction");
  el0.setAttribute("iconsize", "S");
  el0.className += " _coral-Shell-menu-button";
  el0.setAttribute("handle", "shellMenuButton");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["shellMenuButtonLabel"] = document.createElement("coral-button-label");
  el2.setAttribute("handle", "shellMenuButtonLabel");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;