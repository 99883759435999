const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  var buttonId = data.commons.getUID();
  var contentId = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["slider"] = document.createElement("div");
  el2.setAttribute("handle", "slider");
  el2.className += " _coral-Drawer-slider";
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["contentWrapper"] = document.createElement("div");
  el4.setAttribute("handle", "contentWrapper");
  el4.className += " _coral-Drawer-content";
  el4.id = contentId;
  el4.setAttribute("aria-labelledby", buttonId);
  el4.setAttribute("role", "region");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el2.appendChild(el5);
  frag.appendChild(el2);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["toggle"] = document.createElement("div");
  el7.className += " _coral-Drawer-toggle";
  el7.setAttribute("handle", "toggle");
  var el8 = document.createTextNode("\n  ");
  el7.appendChild(el8);
  var el9 = this["toggleButton"] = document.createElement("button","coral-button");
  el9.id = buttonId;
  el9.setAttribute("aria-controls", contentId);
  el9.setAttribute("aria-label", data_0["i18n"]["get"]('More'));
  el9.className += " _coral-Drawer-toggleButton";
  el9.setAttribute("type", "button");
  el9.setAttribute("handle", "toggleButton");
  el9.setAttribute("is", "coral-button");
  el9.setAttribute("variant", "quiet");
  el7.appendChild(el9);
  var el10 = document.createTextNode("\n");
  el7.appendChild(el10);
  frag.appendChild(el7);
  var el11 = document.createTextNode("\n");
  frag.appendChild(el11);
  return frag;
});
export default template;