const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = document.createElement("coral-list-item-content");
  var el1 = document.createTextNode(data_0["title"]+"\n  ");
  el0.appendChild(el1);
  var el2 = document.createElement("div");
  el2.className += " _coral-Shell-help-result-description";
  el2.textContent = data_0["description"];
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;