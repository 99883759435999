const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["input"] = document.createElement("input");
  el0.setAttribute("handle", "input");
  el0.setAttribute("type", "hidden");
  el0.setAttribute("name", "");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["header"] = document.createElement("div");
  el2.setAttribute("handle", "header");
  el2.className += " _coral-Calendar-header";
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["heading"] = document.createElement("div");
  el4.setAttribute("handle", "heading");
  el4.className += " _coral-Calendar-title";
  el4.setAttribute("role", "heading");
  el4.setAttribute("aria-live", "assertive");
  el4.setAttribute("aria-atomic", "true");
  el4.setAttribute("aria-level", "2");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  var el6 = this["prev"] = document.createElement("button","coral-button");
  el6.setAttribute("type", "button");
  el6.setAttribute("handle", "prev");
  el6.className += " _coral-Calendar-prevMonth";
  el6.setAttribute("is", "coral-button");
  el6.setAttribute("variant", "quietaction");
  el6.setAttribute("aria-label", data_0["i18n"]["get"]('Previous Month'));
  el6.setAttribute("title", data_0["i18n"]["get"]('Previous Month'));
  var el7 = document.createTextNode("\n    ");
  el6.appendChild(el7);
  var el8 = this["prevLabel"] = document.createElement("coral-button-label");
  el8.setAttribute("handle", "prevLabel");
  el6.appendChild(el8);
  var el9 = document.createTextNode("\n  ");
  el6.appendChild(el9);
  el2.appendChild(el6);
  var el10 = document.createTextNode("\n  ");
  el2.appendChild(el10);
  var el11 = this["next"] = document.createElement("button","coral-button");
  el11.setAttribute("type", "button");
  el11.setAttribute("handle", "next");
  el11.className += " _coral-Calendar-nextMonth";
  el11.setAttribute("is", "coral-button");
  el11.setAttribute("variant", "quietaction");
  el11.setAttribute("aria-label", data_0["i18n"]["get"]('Next Month'));
  el11.setAttribute("title", data_0["i18n"]["get"]('Next Month'));
  var el12 = document.createTextNode("\n    ");
  el11.appendChild(el12);
  var el13 = this["nextLabel"] = document.createElement("coral-button-label");
  el13.setAttribute("handle", "nextLabel");
  el11.appendChild(el13);
  var el14 = document.createTextNode("\n  ");
  el11.appendChild(el14);
  el2.appendChild(el11);
  var el15 = document.createTextNode("\n  ");
  el2.appendChild(el15);
  data = data_0;
  
    // Don't wait for button MO to pick up the label
    this.prev._elements.label = this.prevLabel;
    this.next._elements.label = this.nextLabel;

    // Render chevron icon
    this.prev.insertAdjacentHTML('beforeend', data.Icon._renderSVG('spectrum-css-icon-ChevronLeftLarge', ['_coral-UIIcon-ChevronLeftLarge']));
    this.next.insertAdjacentHTML('beforeend', data.Icon._renderSVG('spectrum-css-icon-ChevronRightLarge', ['_coral-UIIcon-ChevronRightLarge']));
  
  data_0 = data;
  var el17 = document.createTextNode("\n");
  el2.appendChild(el17);
  frag.appendChild(el2);
  var el18 = document.createTextNode("\n");
  frag.appendChild(el18);
  var el19 = this["body"] = document.createElement("div");
  el19.className += " _coral-Calendar-body";
  el19.setAttribute("handle", "body");
  el19.setAttribute("role", "grid");
  el19.setAttribute("tabindex", "0");
  el19.setAttribute("aria-readonly", "true");
  frag.appendChild(el19);
  var el20 = document.createTextNode("\n");
  frag.appendChild(el20);
  return frag;
});
export default template;