const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["input"] = document.createElement("input");
  el0.setAttribute("type", "radio");
  el0.setAttribute("handle", "input");
  el0.className += " _coral-Radio-input";
  el0.id = data_0["commons"]["getUID"]();
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["checkmark"] = document.createElement("span");
  el2.className += " _coral-Radio-button";
  el2.setAttribute("handle", "checkmark");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  var el4 = this["labelWrapper"] = document.createElement("label");
  el4.className += " _coral-Radio-label";
  el4.setAttribute("handle", "labelWrapper");
  el4.setAttribute("for", this["input"]["id"]);
  var el5 = document.createTextNode("\n  ");
  el4.appendChild(el5);
  var el6 = this["screenReaderOnly"] = document.createElement("span");
  el6.className += " u-coral-screenReaderOnly";
  el6.setAttribute("handle", "screenReaderOnly");
  el6.textContent = data_0["i18n"]["get"]('Select');
  el4.appendChild(el6);
  var el7 = document.createTextNode("\n");
  el4.appendChild(el7);
  frag.appendChild(el4);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  return frag;
});
export default template;