const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  var buttonId = data.commons.getUID();
  var listId = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["button"] = document.createElement("button","coral-button");
  el2.setAttribute("tracking", "off");
  el2.setAttribute("variant", "_custom");
  el2.setAttribute("iconposition", "right");
  el2.setAttribute("handle", "button");
  el2.setAttribute("type", "button");
  el2.setAttribute("is", "coral-button");
  el2.setAttribute("aria-haspopup", "true");
  el2.id = buttonId;
  el2.setAttribute("aria-controls", listId);
  el2.setAttribute("aria-owns", listId);
  el2.setAttribute("aria-expanded", "false");
  el2.className += " _coral-FieldButton _coral-Dropdown-trigger";
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["label"] = document.createElement("coral-button-label");
  el4.className += " _coral-Dropdown-label";
  el4.setAttribute("handle", "label");
  el4.textContent = "​";
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  var el6 = this["invalidIcon"] = document.createElement("coral-icon");
  el6.setAttribute("icon", "alert");
  el6.setAttribute("hidden", "");
  el6.setAttribute("handle", "invalidIcon");
  el6.className += " _coral-Dropdown-invalidIcon";
  el2.appendChild(el6);
  var el7 = document.createTextNode("\n");
  el2.appendChild(el7);
  frag.appendChild(el2);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  data = data_0;
  
  // Don't wait for button MO to pick up the label
  this.button._elements.label = this.label;
  // Render invalid icon
  this.button.insertAdjacentHTML('beforeend', data.Icon._renderSVG('spectrum-css-icon-ChevronDownMedium', ['_coral-Dropdown-icon', '_coral-UIIcon-ChevronDownMedium']));

  data_0 = data;
  var el10 = document.createTextNode("\n");
  frag.appendChild(el10);
  var el11 = this["overlay"] = document.createElement("coral-popover");
  el11.setAttribute("withinoffset", "0");
  el11.setAttribute("tracking", "off");
  el11.className += " _coral-Select-overlay";
  el11.setAttribute("smart", "");
  el11.id = data_0["commons"]["getUID"]();
  el11.setAttribute("handle", "overlay");
  el11.setAttribute("placement", "bottom");
  el11.setAttribute("focusonshow", "coral-selectlist");
  el11.setAttribute("role", "presentation");
  var el12 = document.createTextNode("\n  ");
  el11.appendChild(el12);
  data = data_0;
  
    // Constrains the size of the list to 6 items.
    // @todo move this to theme
    var maxHeight = 'max-height:' + (32 * 6) + 'px';
  
  data_0 = data;
  var el14 = document.createTextNode("\n  ");
  el11.appendChild(el14);
  var el15 = this["list"] = document.createElement("coral-selectlist");
  el15.setAttribute("tracking", "off");
  el15.setAttribute("style", maxHeight);
  el15.setAttribute("handle", "list");
  el15.setAttribute("role", "listbox");
  el15.id = listId;
  el11.appendChild(el15);
  var el16 = document.createTextNode("\n");
  el11.appendChild(el16);
  frag.appendChild(el11);
  var el17 = document.createTextNode("\n");
  frag.appendChild(el17);
  var el18 = this["input"] = document.createElement("input");
  el18.setAttribute("handle", "input");
  el18.setAttribute("type", "hidden");
  frag.appendChild(el18);
  var el19 = document.createTextNode("\n");
  frag.appendChild(el19);
  var el20 = this["nativeSelect"] = document.createElement("select");
  el20.setAttribute("handle", "nativeSelect");
  el20.className += " _coral-Dropdown-select";
  el20.setAttribute("tabindex", "-1");
  frag.appendChild(el20);
  var el21 = document.createTextNode("\n");
  frag.appendChild(el21);
  var el22 = this["taglist"] = document.createElement("coral-taglist");
  el22.setAttribute("tracking", "off");
  el22.setAttribute("handle", "taglist");
  el22.className += " _coral-Dropdown-tagList";
  frag.appendChild(el22);
  var el23 = document.createTextNode("\n");
  frag.appendChild(el23);
  return frag;
});
export default template;