const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  this.i18n = data.i18n;
  this.commons = data.commons;

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["table"] = document.createElement("table");
  el2.setAttribute("handle", "table");
  el2.className += " _coral-Calendar-table";
  el2.setAttribute("role", "presentation");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("thead");
  el4.setAttribute("role", "presentation");
  var el5 = document.createTextNode("\n    ");
  el4.appendChild(el5);
  var el6 = document.createElement("tr");
  el6.setAttribute("role", "row");
  var el7 = document.createTextNode("\n      ");
  el6.appendChild(el7);
  var iterated_1 = data_0["dayNames"];
  for (var i1 = 0, ni1 = iterated_1.length; i1 < ni1; i1++) {
    var data_1 = data = iterated_1[i1];
    var el9 = document.createTextNode("\n        ");
    el6.appendChild(el9);
    var el10 = document.createElement("th");
    el10.setAttribute("role", "columnheader");
    el10.setAttribute("scope", "col");
    el10.className += " _coral-Calendar-tableCell";
    var el11 = document.createTextNode("\n          ");
    el10.appendChild(el11);
    var el12 = document.createElement("abbr");
    el12.className += " _coral-Calendar-dayOfWeek";
    el12.setAttribute("title", data_1["dayFullName"]);
    el12.textContent = data_1["dayAbbr"];
    el10.appendChild(el12);
    var el13 = document.createTextNode("\n        ");
    el10.appendChild(el13);
    el6.appendChild(el10);
    var el14 = document.createTextNode("\n      ");
    el6.appendChild(el14);
  }
  var el15 = document.createTextNode("\n    ");
  el6.appendChild(el15);
  el4.appendChild(el6);
  var el16 = document.createTextNode("\n  ");
  el4.appendChild(el16);
  el2.appendChild(el4);
  var el17 = document.createTextNode("\n  ");
  el2.appendChild(el17);
  var el18 = document.createElement("tbody");
  el18.setAttribute("role", "presentation");
  var el19 = document.createTextNode("\n    ");
  el18.appendChild(el19);
  var iterated_1 = data_0["weeks"];
  for (var i1 = 0, ni1 = iterated_1.length; i1 < ni1; i1++) {
    var data_1 = data = iterated_1[i1];
    var el21 = document.createTextNode("\n      ");
    el18.appendChild(el21);
    var el22 = document.createElement("tr");
    el22.setAttribute("role", "row");
    var el23 = document.createTextNode("\n        ");
    el22.appendChild(el23);
    var iterated_2 = data_1;
    for (var i2 = 0, ni2 = iterated_2.length; i2 < ni2; i2++) {
      var data_2 = data = iterated_2[i2];
      var el25 = document.createTextNode("\n          ");
      el22.appendChild(el25);
      data = data_2;
      
            var calendarTitle;
            var calendarDate = data.dateLabel + ', ' + data.formattedDate;
            if (data.isToday) {
              if (data.ariaSelected) {
                calendarTitle = this.i18n.get('Today, {0} selected', calendarDate, 'date');
              }
              else {
                calendarTitle = this.i18n.get('Today, {0}', calendarDate, 'date');
              }
            }
            else {
              if (data.ariaSelected) {
                calendarTitle = this.i18n.get('{0} selected', calendarDate, 'date');
              }
              else {
                calendarTitle = calendarTitle = calendarDate;
              }
            }
          
      data_2 = data;
      var el27 = document.createTextNode("\n          ");
      el22.appendChild(el27);
      var el28 = this["cell"] = document.createElement("td");
      el28.setAttribute("handle", "cell");
      el28.setAttribute("role", "gridcell");
      el28.id = this["commons"]["getUID"]()+"-row"+i1+"-col"+i2;
      el28.className += " _coral-Calendar-tableCell";
      el28.setAttribute("aria-selected", data_2["ariaSelected"]);
      if (data_2["isDisabled"]) {
      el28.setAttribute("aria-disabled", "true");
      }
      if (data_2["ariaInvalid"]) {
      el28.setAttribute("aria-invalid", "true");
      }
      if (data_2["formattedDate"]) {
      el28.setAttribute("title", calendarTitle);
      }
      var el29 = document.createTextNode("\n            ");
      el28.appendChild(el29);
      data = data_2;
      
              if (data.cssClass.indexOf('is-outsideMonth') !== -1) {
                this.cell.classList.add('is-outsideMonth');
              }
            
      data_2 = data;
      var el31 = document.createTextNode("\n            ");
      el28.appendChild(el31);
      if (data_2["dateAttr"]) {
        var el33 = document.createTextNode("\n                ");
        el28.appendChild(el33);
        var el34 = document.createElement("a");
        el34.setAttribute("role", "presentation");
        el34.className += " _coral-Calendar-date "+data_2["cssClass"];
        el34.setAttribute("data-date", data_2["dateAttr"]);
        el34.textContent = data_2["dateText"];
        el28.appendChild(el34);
        var el35 = document.createTextNode("\n              ");
        el28.appendChild(el35);
        var el36 = document.createTextNode("\n            ");
        el28.appendChild(el36);
      }
      else {
        var el37 = document.createTextNode("\n                ");
        el28.appendChild(el37);
        var el38 = document.createElement("span");
        el38.setAttribute("role", "presentation");
        el38.className += " _coral-Calendar-date "+data_2["cssClass"];
        el38.textContent = data_2["dateText"];
        el28.appendChild(el38);
        var el39 = document.createTextNode("\n              ");
        el28.appendChild(el39);
      }
      var el40 = document.createTextNode("\n          ");
      el28.appendChild(el40);
      el22.appendChild(el28);
      var el41 = document.createTextNode("\n        ");
      el22.appendChild(el41);
    }
    var el42 = document.createTextNode("\n      ");
    el22.appendChild(el42);
    el18.appendChild(el22);
    var el43 = document.createTextNode("\n    ");
    el18.appendChild(el43);
  }
  var el44 = document.createTextNode("\n  ");
  el18.appendChild(el44);
  el2.appendChild(el18);
  var el45 = document.createTextNode("\n  ");
  el2.appendChild(el45);
  var el46 = document.createElement("caption");
  el46.className += " u-coral-screenReaderOnly";
  el46.setAttribute("aria-live", "assertive");
  el46.setAttribute("aria-atomic", "true");
  el2.appendChild(el46);
  var el47 = document.createTextNode("\n");
  el2.appendChild(el47);
  frag.appendChild(el2);
  var el48 = document.createTextNode("\n");
  frag.appendChild(el48);
  return frag;
});
export default template;