const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  var uid = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["calendar"] = document.createElement("coral-calendar");
  el2.setAttribute("handle", "calendar");
  el2.className += " _coral-Datepicker-calendar";
  el2.setAttribute("aria-labelledby", uid+"-calendar");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("span");
  el4.id = uid+"-calendar";
  el4.className += " u-coral-screenReaderOnly";
  el4.setAttribute("hidden", "");
  el4.textContent = data_0["i18n"]["get"]("Calendar");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el2.appendChild(el5);
  frag.appendChild(el2);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["clock"] = document.createElement("coral-clock");
  el7.setAttribute("handle", "clock");
  el7.className += " _coral-Datepicker-clock";
  el7.setAttribute("aria-labelledby", uid+"-clock");
  var el8 = document.createTextNode("\n  ");
  el7.appendChild(el8);
  var el9 = document.createElement("span");
  el9.id = uid+"-clock";
  el9.className += " u-coral-screenReaderOnly";
  el9.setAttribute("hidden", "");
  el9.textContent = data_0["i18n"]["get"]("Time");
  el7.appendChild(el9);
  var el10 = document.createTextNode("\n");
  el7.appendChild(el10);
  frag.appendChild(el7);
  var el11 = document.createTextNode("\n");
  frag.appendChild(el11);
  return frag;
});
export default template;