const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["input"] = document.createElement("input");
  el0.id = data_0["commons"]["getUID"]();
  el0.setAttribute("handle", "input");
  el0.className += " _coral-FileUpload-input is-unselectable";
  el0.setAttribute("tabindex", "-1");
  el0.setAttribute("name", "");
  el0.setAttribute("type", "file");
  el0.setAttribute("accept", "");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;