const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["body"] = document.createElement("div");
  el0.className += " _coral-Toast-body";
  el0.setAttribute("handle", "body");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["buttons"] = document.createElement("div");
  el2.className += " _coral-Toast-buttons";
  el2.setAttribute("handle", "buttons");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("button","coral-button");
  el4.setAttribute("tracking", "off");
  el4.setAttribute("is", "coral-button");
  el4.setAttribute("type", "button");
  el4.setAttribute("variant", "_custom");
  el4.className += " _coral-ClearButton _coral-ClearButton--medium _coral-ClearButton--overBackground";
  el4.setAttribute("coral-close", "");
  var el5 = document.createTextNode("\n    ");
  el4.appendChild(el5);
  var el6 = document.createElement("coral-icon");
  el6.setAttribute("icon", "spectrum-css-icon-CrossMedium");
  el6.className += " _coral-UIIcon-CrossMedium";
  el4.appendChild(el6);
  var el7 = document.createTextNode("\n    ");
  el4.appendChild(el7);
  var el8 = this["buttonLabel"] = document.createElement("coral-button-label");
  el8.setAttribute("handle", "buttonLabel");
  el4.appendChild(el8);
  var el9 = document.createTextNode("\n  ");
  el4.appendChild(el9);
  el2.appendChild(el4);
  var el10 = document.createTextNode("\n");
  el2.appendChild(el10);
  frag.appendChild(el2);
  var el11 = document.createTextNode("\n");
  frag.appendChild(el11);
  return frag;
});
export default template;