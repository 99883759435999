const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  data.uidMin = data.commons.getUID();
  data.uidMax = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n\n");
  frag.appendChild(el1);
  var el2 = this["label"] = document.createElement("div");
  el2.className += " _coral-Slider-labelContainer";
  el2.setAttribute("handle", "label");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["labelContent"] = document.createElement("label");
  el4.className += " _coral-Slider-label";
  el4.setAttribute("handle", "labelContent");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  var el6 = this["labelValue"] = document.createElement("label");
  el6.className += " _coral-Slider-value";
  el6.setAttribute("handle", "labelValue");
  el6.setAttribute("hidden", "");
  el2.appendChild(el6);
  var el7 = document.createTextNode("\n");
  el2.appendChild(el7);
  frag.appendChild(el2);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  var el9 = this["controls"] = document.createElement("div");
  el9.className += " _coral-Slider-controls";
  el9.setAttribute("role", "presentation");
  el9.setAttribute("handle", "controls");
  var el10 = document.createTextNode("\n  ");
  el9.appendChild(el10);
  var el11 = document.createElement("div");
  el11.className += " _coral-Slider-track";
  el9.appendChild(el11);
  var el12 = document.createTextNode("\n  ");
  el9.appendChild(el12);
  var el13 = this["leftHandle"] = document.createElement("div");
  el13.className += " _coral-Slider-handle";
  el13.setAttribute("handle", "leftHandle");
  var el14 = document.createTextNode("\n    ");
  el13.appendChild(el14);
  var el15 = this["leftLabel"] = document.createElement("label");
  el15.setAttribute("handle", "leftLabel");
  el15.className += " u-coral-screenReaderOnly";
  el15.id = data_0["uidMin"]+"-label";
  el15.setAttribute("for", data_0["uidMin"]);
  el15.setAttribute("aria-hidden", "true");
  el15.textContent = "Minimum";
  el13.appendChild(el15);
  var el16 = document.createTextNode("\n    ");
  el13.appendChild(el16);
  var el17 = this["leftInput"] = document.createElement("input");
  el17.setAttribute("handle", "leftInput");
  el17.setAttribute("value", "1");
  el17.setAttribute("type", "range");
  el17.className += " _coral-Slider-input";
  el17.id = data_0["uidMin"];
  el13.appendChild(el17);
  var el18 = document.createTextNode("\n  ");
  el13.appendChild(el18);
  el9.appendChild(el13);
  var el19 = document.createTextNode("\n  ");
  el9.appendChild(el19);
  var el20 = document.createElement("div");
  el20.className += " _coral-Slider-track";
  el9.appendChild(el20);
  var el21 = document.createTextNode("\n  ");
  el9.appendChild(el21);
  var el22 = this["rightHandle"] = document.createElement("div");
  el22.className += " _coral-Slider-handle";
  el22.setAttribute("handle", "rightHandle");
  var el23 = document.createTextNode("\n    ");
  el22.appendChild(el23);
  var el24 = this["rightLabel"] = document.createElement("label");
  el24.setAttribute("handle", "rightLabel");
  el24.className += " u-coral-screenReaderOnly";
  el24.id = data_0["uidMax"]+"-label";
  el24.setAttribute("for", data_0["uidMax"]);
  el24.setAttribute("aria-hidden", "true");
  el24.textContent = "Maximum";
  el22.appendChild(el24);
  var el25 = document.createTextNode("\n    ");
  el22.appendChild(el25);
  var el26 = this["rightInput"] = document.createElement("input");
  el26.setAttribute("handle", "rightInput");
  el26.setAttribute("value", "100");
  el26.setAttribute("type", "range");
  el26.className += " _coral-Slider-input";
  el26.id = data_0["uidMax"];
  el22.appendChild(el26);
  var el27 = document.createTextNode("\n  ");
  el22.appendChild(el27);
  el9.appendChild(el22);
  var el28 = document.createTextNode("\n  ");
  el9.appendChild(el28);
  var el29 = document.createElement("div");
  el29.className += " _coral-Slider-track";
  el9.appendChild(el29);
  var el30 = document.createTextNode("\n");
  el9.appendChild(el30);
  frag.appendChild(el9);
  var el31 = document.createTextNode("\n");
  frag.appendChild(el31);
  return frag;
});
export default template;