const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["percentage"] = document.createElement("div");
  el0.className += " _coral-BarLoader-percentage";
  el0.setAttribute("handle", "percentage");
  el0.setAttribute("aria-hidden", "true");
  el0.setAttribute("style", "visibility: hidden");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["bar"] = document.createElement("div");
  el2.setAttribute("handle", "bar");
  el2.className += " _coral-BarLoader-track";
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["status"] = document.createElement("div");
  el4.className += " _coral-BarLoader-fill";
  el4.setAttribute("handle", "status");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el2.appendChild(el5);
  frag.appendChild(el2);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  return frag;
});
export default template;