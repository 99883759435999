const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["moreButton"] = document.createElement("button","coral-button");
  el0.setAttribute("is", "coral-button");
  el0.setAttribute("handle", "moreButton");
  el0.setAttribute("type", "button");
  el0.setAttribute("variant", "quietaction");
  el0.className += " _coral-ActionBar-button";
  el0.setAttribute("icon", "more");
  el0.setAttribute("coral-actionbar-more", "");
  el0.setAttribute("aria-expanded", "false");
  el0.setAttribute("aria-haspopup", "true");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["moreButtonLabel"] = document.createElement("coral-button-label");
  el2.setAttribute("handle", "moreButtonLabel");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;