const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["swatchesSubview"] = document.createElement("div");
  el0.className += " _coral-ColorInput-swatchesSubview";
  el0.setAttribute("role", "presentation");
  el0.setAttribute("handle", "swatchesSubview");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = document.createElement("div");
  el2.className += " _coral-ColorInput-swatches-header-title coral-Heading coral-Heading--5";
  el2.setAttribute("role", "presentation");
  el2.setAttribute("aria-hidden", "true");
  var el3 = document.createTextNode("\n    ");
  el2.appendChild(el3);
  var el4 = this["swatchesHeaderTitle"] = document.createElement("span");
  el4.setAttribute("handle", "swatchesHeaderTitle");
  el4.id = data_0["commons"]["getUID"]();
  el4.setAttribute("role", "heading");
  el4.setAttribute("aria-level", "2");
  el4.textContent = "\n      "+data_0["i18n"]["get"]('Swatches');
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  el0.appendChild(el2);
  var el6 = document.createTextNode("\n  ");
  el0.appendChild(el6);
  var el7 = document.createElement("div");
  el7.className += " _coral-ColorInput-swatches-container";
  el7.setAttribute("role", "presentation");
  el7.setAttribute("aria-labelledby", data_0["commons"]["getUID"]());
  var el8 = document.createTextNode("\n    ");
  el7.appendChild(el8);
  var el9 = this["swatchesContainer"] = document.createElement("div");
  el9.setAttribute("handle", "swatchesContainer");
  el9.setAttribute("role", "presentation");
  el7.appendChild(el9);
  var el10 = document.createTextNode("\n  ");
  el7.appendChild(el10);
  el0.appendChild(el7);
  var el11 = document.createTextNode("\n");
  el0.appendChild(el11);
  frag.appendChild(el0);
  var el12 = document.createTextNode("\n");
  frag.appendChild(el12);
  return frag;
});
export default template;