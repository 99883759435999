const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["icon"] = document.createElement("coral-icon");
  el0.setAttribute("handle", "icon");
  el0.className += " _coral-Shell-homeAnchor-icon";
  el0.setAttribute("size", "M");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;