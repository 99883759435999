const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["input"] = document.createElement("input","coral-textfield");
  el0.setAttribute("type", "search");
  el0.className += " _coral-Search-input";
  el0.setAttribute("is", "coral-textfield");
  el0.setAttribute("handle", "input");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["clearButton"] = document.createElement("button","coral-button");
  el2.setAttribute("type", "button");
  el2.setAttribute("aria-label", data_0["i18n"]["get"]('Clear search'));
  el2.setAttribute("is", "coral-button");
  el2.setAttribute("variant", "_custom");
  el2.className += " _coral-Search-clear _coral-ClearButton";
  el2.setAttribute("handle", "clearButton");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["clearButtonLabel"] = document.createElement("coral-button-label");
  el4.setAttribute("handle", "clearButtonLabel");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  data = data_0;
  
    
      // Don't wait for button MO to pick up the label
      this.clearButton._elements.label = this.clearButtonLabel;
      // Render icon
      this.clearButton.insertAdjacentHTML('beforeend', data.Icon._renderSVG('spectrum-css-icon-CrossSmall', ['_coral-UIIcon-CrossSmall']));
    
  
  data_0 = data;
  var el7 = document.createTextNode("\n");
  el2.appendChild(el7);
  frag.appendChild(el2);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  return frag;
});
export default template;