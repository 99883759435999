const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["container"] = document.createElement("div");
  el0.className += " _coral-SideNav-itemLink";
  el0.setAttribute("handle", "container");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["icon"] = document.createElement("coral-icon");
  el2.setAttribute("hidden", "");
  el2.className += " _coral-UIIcon-Star _coral-SideNav-itemIcon";
  el2.setAttribute("handle", "icon");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;