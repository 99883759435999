const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["colorButton"] = document.createElement("button","coral-button");
  el0.setAttribute("is", "coral-button");
  el0.setAttribute("variant", "action");
  el0.setAttribute("handle", "colorButton");
  el0.setAttribute("icon", "checkmark");
  el0.setAttribute("type", "button");
  el0.setAttribute("role", "presentation");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  data = data_0;
  this.colorButton.label.classList.add('u-coral-screenReaderOnly');
  data_0 = data;
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  return frag;
});
export default template;