const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["heading"] = document.createElement("h3");
  el0.setAttribute("handle", "heading");
  el0.className += " _coral-Accordion-itemHeading";
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["button"] = document.createElement("button");
  el2.setAttribute("handle", "button");
  el2.className += " _coral-Accordion-itemHeader";
  el2.setAttribute("type", "button");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n  ");
  el0.appendChild(el3);
  data = data_0;
  
    const iconId = 'spectrum-css-icon-ChevronRightMedium';
    const classes = ['_coral-Accordion-itemIndicator', '_coral-UIIcon-ChevronRightMedium'];

    this.button.insertAdjacentHTML('afterend', data.Icon._renderSVG(iconId, classes));
  
  data_0 = data;
  var el5 = document.createTextNode("\n");
  el0.appendChild(el5);
  frag.appendChild(el0);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  return frag;
});
export default template;