const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["header"] = document.createElement("div");
  el0.className += " _coral-TreeView-itemLink";
  el0.setAttribute("tabindex", "-1");
  el0.id = data_0["commons"]["getUID"]();
  el0.setAttribute("handle", "header");
  el0.setAttribute("role", "treeitem");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["selectedState"] = document.createElement("span");
  el2.className += " u-coral-screenReaderOnly";
  el2.setAttribute("handle", "selectedState");
  el2.setAttribute("role", "presentation");
  el2.id = data_0["commons"]["getUID"]();
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  data = data_0;
  
  this.header.insertAdjacentHTML('afterbegin', data.Icon._renderSVG('spectrum-css-icon-ChevronRightMedium', ['_coral-TreeView-indicator', '_coral-UIIcon-ChevronRightMedium']));

  data_0 = data;
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["subTreeContainer"] = document.createElement("div");
  el7.className += " _coral-TreeView";
  el7.setAttribute("handle", "subTreeContainer");
  el7.id = data_0["commons"]["getUID"]();
  el7.setAttribute("role", "group");
  frag.appendChild(el7);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  return frag;
});
export default template;