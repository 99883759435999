const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  data.uid = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["input"] = document.createElement("input","coral-textfield");
  el2.setAttribute("is", "coral-textfield");
  el2.setAttribute("handle", "input");
  el2.setAttribute("type", "number");
  el2.className += " _coral-Stepper-input";
  el2.id = data_0["uid"];
  el2.setAttribute("step", "1");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  var el4 = this["presentation"] = document.createElement("span");
  el4.className += " _coral-Stepper-buttons";
  el4.setAttribute("role", "presentation");
  el4.setAttribute("handle", "presentation");
  var el5 = document.createTextNode("\n  ");
  el4.appendChild(el5);
  var el6 = this["stepUp"] = document.createElement("button","coral-button");
  el6.setAttribute("variant", "action");
  el6.className += " _coral-Stepper-stepUp";
  el6.setAttribute("type", "button");
  el6.setAttribute("is", "coral-button");
  el6.setAttribute("handle", "stepUp");
  el6.setAttribute("title", data_0["i18n"]["get"]('Increment'));
  el6.setAttribute("aria-label", data_0["i18n"]["get"]('Increment'));
  el6.setAttribute("tabindex", "-1");
  el6.setAttribute("aria-controls", data_0["uid"]);
  var el7 = document.createTextNode("\n    ");
  el6.appendChild(el7);
  data = data_0;
  
      this.stepUp.innerHTML = data.Icon._renderSVG('spectrum-css-icon-ChevronUpSmall', ['_coral-Stepper-stepUpIcon', '_coral-UIIcon-ChevronUpSmall']);
    
  data_0 = data;
  var el9 = document.createTextNode("\n  ");
  el6.appendChild(el9);
  el4.appendChild(el6);
  var el10 = document.createTextNode("\n  ");
  el4.appendChild(el10);
  var el11 = this["stepDown"] = document.createElement("button","coral-button");
  el11.setAttribute("variant", "action");
  el11.className += " _coral-Stepper-stepDown";
  el11.setAttribute("type", "button");
  el11.setAttribute("is", "coral-button");
  el11.setAttribute("handle", "stepDown");
  el11.setAttribute("title", data_0["i18n"]["get"]('Decrement'));
  el11.setAttribute("aria-label", data_0["i18n"]["get"]('Decrement'));
  el11.setAttribute("tabindex", "-1");
  el11.setAttribute("aria-controls", data_0["uid"]);
  var el12 = document.createTextNode("\n    ");
  el11.appendChild(el12);
  data = data_0;
  
      this.stepDown.innerHTML = data.Icon._renderSVG('spectrum-css-icon-ChevronDownSmall', ['_coral-Stepper-stepDownIcon', '_coral-UIIcon-ChevronDownSmall']);
    
  data_0 = data;
  var el14 = document.createTextNode("\n  ");
  el11.appendChild(el14);
  el4.appendChild(el11);
  var el15 = document.createTextNode("\n  ");
  el4.appendChild(el15);
  var el16 = this["liveregion"] = document.createElement("span");
  el16.setAttribute("role", "presentation");
  el16.setAttribute("handle", "liveregion");
  el16.setAttribute("aria-live", "assertive");
  el16.setAttribute("aria-atomic", "true");
  el16.setAttribute("aria-relevant", "additions text");
  el16.className += " u-coral-screenReaderOnly";
  el16.setAttribute("hidden", "");
  el4.appendChild(el16);
  var el17 = document.createTextNode("\n");
  el4.appendChild(el17);
  frag.appendChild(el4);
  var el18 = document.createTextNode("\n");
  frag.appendChild(el18);
  return frag;
});
export default template;