const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["defaultPalette"] = document.createElement("div");
  el0.setAttribute("handle", "defaultPalette");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = document.createElement("coral-colorinput-item");
  el2.setAttribute("value", "");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n  ");
  el0.appendChild(el3);
  var el4 = document.createElement("coral-colorinput-item");
  el4.setAttribute("value", "#001F3F");
  el0.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el0.appendChild(el5);
  var el6 = document.createElement("coral-colorinput-item");
  el6.setAttribute("value", "#0074D9");
  el0.appendChild(el6);
  var el7 = document.createTextNode("\n  ");
  el0.appendChild(el7);
  var el8 = document.createElement("coral-colorinput-item");
  el8.setAttribute("value", "#7FDBFF");
  el0.appendChild(el8);
  var el9 = document.createTextNode("\n  ");
  el0.appendChild(el9);
  var el10 = document.createElement("coral-colorinput-item");
  el10.setAttribute("value", "#39CCCC");
  el0.appendChild(el10);
  var el11 = document.createTextNode("\n  ");
  el0.appendChild(el11);
  var el12 = document.createElement("coral-colorinput-item");
  el12.setAttribute("value", "#3D9970");
  el0.appendChild(el12);
  var el13 = document.createTextNode("\n  ");
  el0.appendChild(el13);
  var el14 = document.createElement("coral-colorinput-item");
  el14.setAttribute("value", "#2ECC40");
  el0.appendChild(el14);
  var el15 = document.createTextNode("\n  ");
  el0.appendChild(el15);
  var el16 = document.createElement("coral-colorinput-item");
  el16.setAttribute("value", "#01FF70");
  el0.appendChild(el16);
  var el17 = document.createTextNode("\n  ");
  el0.appendChild(el17);
  var el18 = document.createElement("coral-colorinput-item");
  el18.setAttribute("value", "#FFDC00");
  el0.appendChild(el18);
  var el19 = document.createTextNode("\n  ");
  el0.appendChild(el19);
  var el20 = document.createElement("coral-colorinput-item");
  el20.setAttribute("value", "#FF851B");
  el0.appendChild(el20);
  var el21 = document.createTextNode("\n  ");
  el0.appendChild(el21);
  var el22 = document.createElement("coral-colorinput-item");
  el22.setAttribute("value", "#FF4136");
  el0.appendChild(el22);
  var el23 = document.createTextNode("\n  ");
  el0.appendChild(el23);
  var el24 = document.createElement("coral-colorinput-item");
  el24.setAttribute("value", "#85144B");
  el0.appendChild(el24);
  var el25 = document.createTextNode("\n  ");
  el0.appendChild(el25);
  var el26 = document.createElement("coral-colorinput-item");
  el26.setAttribute("value", "#F012BE");
  el0.appendChild(el26);
  var el27 = document.createTextNode("\n  ");
  el0.appendChild(el27);
  var el28 = document.createElement("coral-colorinput-item");
  el28.setAttribute("value", "#B10DC9");
  el0.appendChild(el28);
  var el29 = document.createTextNode("\n  ");
  el0.appendChild(el29);
  var el30 = document.createElement("coral-colorinput-item");
  el30.setAttribute("value", "#111111");
  el0.appendChild(el30);
  var el31 = document.createTextNode("\n  ");
  el0.appendChild(el31);
  var el32 = document.createElement("coral-colorinput-item");
  el32.setAttribute("value", "#AAAAAA");
  el0.appendChild(el32);
  var el33 = document.createTextNode("\n  ");
  el0.appendChild(el33);
  var el34 = document.createElement("coral-colorinput-item");
  el34.setAttribute("value", "#DDDDDD");
  el0.appendChild(el34);
  var el35 = document.createTextNode("\n");
  el0.appendChild(el35);
  frag.appendChild(el0);
  var el36 = document.createTextNode("\n");
  frag.appendChild(el36);
  data = data_0;
  var uid = data.commons.getUID();
  data_0 = data;
  var el38 = document.createTextNode("\n");
  frag.appendChild(el38);
  var el39 = this["input"] = document.createElement("input","coral-textfield");
  el39.setAttribute("handle", "input");
  el39.setAttribute("is", "coral-textfield");
  el39.className += " _coral-ColorInput-input";
  el39.setAttribute("type", "text");
  el39.setAttribute("value", "");
  frag.appendChild(el39);
  var el40 = document.createTextNode("\n");
  frag.appendChild(el40);
  var el41 = this["buttonWrapper"] = document.createElement("div");
  el41.className += " _coral-ColorInput-buttonWrapper";
  el41.setAttribute("handle", "buttonWrapper");
  el41.setAttribute("role", "presentation");
  var el42 = document.createTextNode("\n  ");
  el41.appendChild(el42);
  var el43 = this["colorPreview"] = document.createElement("button","coral-button");
  el43.setAttribute("handle", "colorPreview");
  el43.setAttribute("is", "coral-button");
  el43.setAttribute("variant", "_custom");
  el43.className += " _coral-FieldButton _coral-ColorInput-button _coral-ColorInput-preview";
  el43.setAttribute("type", "button");
  el43.setAttribute("aria-haspopup", "true");
  el43.setAttribute("aria-expanded", "false");
  el43.setAttribute("aria-controls", uid);
  el41.appendChild(el43);
  var el44 = document.createTextNode("\n  ");
  el41.appendChild(el44);
  data = data_0;
  
    // Since we don't have a mutation observer anymore to replace content zones magically, we have to do it manually.
    this.colorPreview.label.classList.add('u-coral-screenReaderOnly');
    this.colorPreview.label.innerText = data.i18n.get('Color Picker');
    this.id = uid + '-coral-button-label';
  
  data_0 = data;
  var el46 = document.createTextNode("\n");
  el41.appendChild(el46);
  frag.appendChild(el41);
  var el47 = document.createTextNode("\n");
  frag.appendChild(el47);
  var el48 = this["overlay"] = document.createElement("coral-popover");
  el48.setAttribute("smart", "");
  el48.className += " _coral-ColorInput-overlay";
  el48.setAttribute("role", "dialog");
  el48.setAttribute("focusonshow", "on");
  el48.setAttribute("trapfocus", "on");
  el48.setAttribute("handle", "overlay");
  el48.setAttribute("breadthoffset", "50%r - 50%p");
  el48.setAttribute("placement", "bottom");
  el48.id = uid;
  el48.setAttribute("aria-label", data_0["i18n"]["get"]('Color Picker'));
  var el49 = document.createTextNode("\n  ");
  el48.appendChild(el49);
  var el50 = this["propertiesView"] = document.createElement("coral-colorinput-colorproperties");
  el50.setAttribute("handle", "propertiesView");
  el48.appendChild(el50);
  var el51 = document.createTextNode("\n  ");
  el48.appendChild(el51);
  var el52 = this["swatchesView"] = document.createElement("coral-colorinput-swatches");
  el52.setAttribute("handle", "swatchesView");
  el48.appendChild(el52);
  var el53 = document.createTextNode("\n");
  el48.appendChild(el53);
  frag.appendChild(el48);
  var el54 = document.createTextNode("\n");
  frag.appendChild(el54);
  return frag;
});
export default template;