const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["nativeSelect"] = document.createElement("select");
  el0.className += " _coral-ButtonGroup-select";
  el0.setAttribute("tabindex", "-1");
  el0.setAttribute("handle", "nativeSelect");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;