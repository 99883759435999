const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  data.buttonId = data.id + '-button';
  data.menuId = data.id + '-menu';

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["button"] = document.createElement("button","coral-button");
  el2.setAttribute("tracking", "off");
  el2.id = data_0["buttonId"];
  el2.setAttribute("handle", "button");
  el2.className += " _coral-CycleSelect-button";
  el2.setAttribute("is", "coral-button");
  el2.setAttribute("type", "button");
  el2.setAttribute("variant", "quietaction");
  el2.setAttribute("iconsize", "S");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["label"] = document.createElement("coral-button-label");
  el4.className += " _coral-ActionButton-label";
  el4.setAttribute("handle", "label");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el2.appendChild(el5);
  frag.appendChild(el2);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  data = data_0;
  
  // Don't wait for button MO to pick up the label
  this.button._elements.label = this.label;
  // Render chevron icon
  this.button.insertAdjacentHTML('afterbegin', data.Icon._renderSVG('spectrum-css-icon-ChevronDownMedium', ['_coral-CycleSelect-icon', '_coral-UIIcon-ChevronDownMedium']));

  data_0 = data;
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  var el9 = this["overlay"] = document.createElement("coral-popover");
  el9.setAttribute("tracking", "off");
  el9.setAttribute("smart", "");
  el9.id = data_0["menuId"];
  el9.setAttribute("handle", "overlay");
  el9.setAttribute("focusonshow", "coral-selectlist");
  el9.setAttribute("placement", "bottom");
  el9.setAttribute("aria-live", "off");
  el9.setAttribute("role", "menu");
  var el10 = document.createTextNode("\n  ");
  el9.appendChild(el10);
  var el11 = this["selectList"] = document.createElement("coral-selectlist");
  el11.setAttribute("role", "group");
  el11.setAttribute("tracking", "off");
  el11.className += " _coral-CycleSelect-list";
  el11.setAttribute("handle", "selectList");
  el11.id = data_0["commons"]["getUID"]();
  el9.appendChild(el11);
  var el12 = document.createTextNode("\n  ");
  el9.appendChild(el12);
  var el13 = this["separator"] = document.createElement("div");
  el13.setAttribute("role", "separator");
  el13.setAttribute("handle", "separator");
  el13.className += " _coral-CycleButton-separator";
  el13.setAttribute("hidden", "");
  el9.appendChild(el13);
  var el14 = document.createTextNode("\n  ");
  el9.appendChild(el14);
  var el15 = this["actionList"] = document.createElement("coral-buttonlist");
  el15.setAttribute("role", "group");
  el15.setAttribute("tracking", "off");
  el15.className += " _coral-CycleSelect-buttonList";
  el15.setAttribute("handle", "actionList");
  el15.setAttribute("hidden", "");
  el9.appendChild(el15);
  var el16 = document.createTextNode("\n");
  el9.appendChild(el16);
  frag.appendChild(el9);
  var el17 = document.createTextNode("\n");
  frag.appendChild(el17);
  return frag;
});
export default template;