const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
var labelId = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["contentWrapper"] = document.createElement("div");
  el2.setAttribute("handle", "contentWrapper");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("label");
  el4.className += " u-coral-screenReaderOnly";
  el4.id = labelId;
  el4.textContent = data_0["i18n"]["get"]('Search for Help');
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  var el6 = this["search"] = document.createElement("coral-search");
  el6.className += " _coral-Shell-help-search";
  el6.setAttribute("handle", "search");
  el6.setAttribute("placeholder", data_0["i18n"]["get"]('Search for Help'));
  el6.setAttribute("labelledby", labelId);
  el2.appendChild(el6);
  var el7 = document.createTextNode("\n  ");
  el2.appendChild(el7);
  var el8 = this["items"] = document.createElement("div");
  el8.className += " _coral-Shell-help-items";
  el8.setAttribute("handle", "items");
  el2.appendChild(el8);
  var el9 = document.createTextNode("\n  ");
  el2.appendChild(el9);
  var el10 = this["results"] = document.createElement("coral-anchorlist");
  el10.className += " _coral-Shell-help-results";
  el10.setAttribute("handle", "results");
  el10.setAttribute("hidden", "");
  el2.appendChild(el10);
  var el11 = document.createTextNode("\n  ");
  el2.appendChild(el11);
  var el12 = this["resultMessage"] = document.createElement("div");
  el12.className += " _coral-Shell-help-resultMessage";
  el12.setAttribute("handle", "resultMessage");
  el12.setAttribute("hidden", "");
  el2.appendChild(el12);
  var el13 = document.createTextNode("\n  ");
  el2.appendChild(el13);
  var el14 = this["loading"] = document.createElement("div");
  el14.className += " _coral-Shell-help-loading";
  el14.setAttribute("handle", "loading");
  el14.setAttribute("hidden", "");
  var el15 = document.createTextNode("\n    ");
  el14.appendChild(el15);
  var el16 = document.createElement("coral-wait");
  el16.setAttribute("size", "M");
  el16.className += " _coral-Shell-help-loading-wait";
  el14.appendChild(el16);
  var el17 = document.createTextNode("\n    ");
  el14.appendChild(el17);
  var el18 = document.createElement("span");
  el18.className += " coral-Heading--2 _coral-Shell-help-loading-info";
  el18.textContent = data_0["i18n"]["get"]('Searching Help…');
  el14.appendChild(el18);
  var el19 = document.createTextNode("\n  ");
  el14.appendChild(el19);
  el2.appendChild(el14);
  var el20 = document.createTextNode("\n");
  el2.appendChild(el20);
  frag.appendChild(el2);
  var el21 = document.createTextNode("\n");
  frag.appendChild(el21);
  return frag;
});
export default template;