const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["button"] = document.createElement("button","coral-button");
  el0.setAttribute("tracking", "off");
  el0.setAttribute("handle", "button");
  el0.setAttribute("is", "coral-button");
  el0.setAttribute("type", "button");
  el0.setAttribute("variant", "_custom");
  el0.className += " _coral-ClearButton _coral-ClearButton--small _coral-Tags-item-removeButton";
  el0.setAttribute("title", data_0["i18n"]["get"]('Remove'));
  el0.setAttribute("hidden", "");
  el0.setAttribute("tabindex", "-1");
  el0.setAttribute("coral-close", "");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["buttonLabel"] = document.createElement("coral-button-label");
  el2.setAttribute("handle", "buttonLabel");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  data = data_0;
  
  // Don't wait for button MO to pick up the label
  this.button._elements.label = this.buttonLabel;
  // Render close icon
  this.button.insertAdjacentHTML('beforeend', data.Icon._renderSVG('spectrum-css-icon-CrossSmall', ['_coral-UIIcon-CrossSmall']));

  data_0 = data;
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  return frag;
});
export default template;