const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["track"] = document.createElement("div");
  el0.setAttribute("handle", "track");
  el0.className += " _coral-CircleLoader-track";
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["fills"] = document.createElement("div");
  el2.setAttribute("handle", "fills");
  el2.className += " _coral-CircleLoader-fills";
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("div");
  el4.className += " _coral-CircleLoader-fillMask1";
  var el5 = document.createTextNode("\n    ");
  el4.appendChild(el5);
  var el6 = this["subMask1"] = document.createElement("div");
  el6.setAttribute("handle", "subMask1");
  el6.className += " _coral-CircleLoader-fillSubMask1";
  var el7 = document.createTextNode("\n      ");
  el6.appendChild(el7);
  var el8 = document.createElement("div");
  el8.className += " _coral-CircleLoader-fill";
  el6.appendChild(el8);
  var el9 = document.createTextNode("\n    ");
  el6.appendChild(el9);
  el4.appendChild(el6);
  var el10 = document.createTextNode("\n  ");
  el4.appendChild(el10);
  el2.appendChild(el4);
  var el11 = document.createTextNode("\n  ");
  el2.appendChild(el11);
  var el12 = document.createElement("div");
  el12.className += " _coral-CircleLoader-fillMask2";
  var el13 = document.createTextNode("\n    ");
  el12.appendChild(el13);
  var el14 = this["subMask2"] = document.createElement("div");
  el14.setAttribute("handle", "subMask2");
  el14.className += " _coral-CircleLoader-fillSubMask2";
  var el15 = document.createTextNode("\n      ");
  el14.appendChild(el15);
  var el16 = document.createElement("div");
  el16.className += " _coral-CircleLoader-fill";
  el14.appendChild(el16);
  var el17 = document.createTextNode("\n    ");
  el14.appendChild(el17);
  el12.appendChild(el14);
  var el18 = document.createTextNode("\n  ");
  el12.appendChild(el18);
  el2.appendChild(el12);
  var el19 = document.createTextNode("\n");
  el2.appendChild(el19);
  frag.appendChild(el2);
  var el20 = document.createTextNode("\n\n");
  frag.appendChild(el20);
  return frag;
});
export default template;