const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["overlay"] = document.createElement("coral-popover");
  el0.setAttribute("smart", "");
  el0.id = data_0["commons"]["getUID"]();
  el0.setAttribute("handle", "overlay");
  el0.setAttribute("placement", "bottom");
  el0.setAttribute("breadthoffset", "-50%r + 50%p");
  el0.setAttribute("coral-actionbar-popover", "");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;