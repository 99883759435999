const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = document.createElement("div");
  el0.className += " _coral-Shell-help-resultMessage-container";
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = document.createElement("div");
  el2.className += " coral-Heading--1 _coral-Shell-help-resultMessage-heading";
  el2.textContent = data_0["i18n"]["get"]('Error fetching results');
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;