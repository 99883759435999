const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["stepMarkerContainer"] = document.createElement("span");
  el0.className += " _coral-Steplist-markerContainer";
  el0.setAttribute("handle", "stepMarkerContainer");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = document.createElement("span");
  el2.className += " _coral-Steplist-marker";
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  var el5 = this["overlay"] = document.createElement("coral-tooltip");
  el5.setAttribute("tracking", "off");
  el5.setAttribute("smart", "");
  el5.setAttribute("focusonshow", "off");
  el5.setAttribute("handle", "overlay");
  el5.setAttribute("placement", "top");
  el5.setAttribute("variant", "inspect");
  el5.setAttribute("interaction", "off");
  el5.setAttribute("breadthoffset", "1");
  frag.appendChild(el5);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["line"] = document.createElement("span");
  el7.className += " _coral-Steplist-segment";
  el7.setAttribute("handle", "line");
  frag.appendChild(el7);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  return frag;
});
export default template;