const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["tip"] = document.createElement("span");
  el0.className += " _coral-Tooltip-tip";
  el0.setAttribute("handle", "tip");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;