const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["label"] = document.createElement("div");
  el0.className += " _coral-Slider-labelContainer";
  el0.setAttribute("handle", "label");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["labelContent"] = document.createElement("label");
  el2.className += " _coral-Slider-label";
  el2.setAttribute("handle", "labelContent");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n  ");
  el0.appendChild(el3);
  var el4 = this["labelValue"] = document.createElement("label");
  el4.className += " _coral-Slider-value";
  el4.setAttribute("handle", "labelValue");
  el4.setAttribute("hidden", "");
  el0.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el0.appendChild(el5);
  frag.appendChild(el0);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["controls"] = document.createElement("div");
  el7.className += " _coral-Slider-controls";
  el7.setAttribute("role", "presentation");
  el7.setAttribute("handle", "controls");
  var el8 = document.createTextNode("\n  ");
  el7.appendChild(el8);
  var el9 = document.createElement("div");
  el9.className += " _coral-Slider-track";
  el7.appendChild(el9);
  var el10 = document.createTextNode("\n  ");
  el7.appendChild(el10);
  var el11 = this["leftHandle"] = document.createElement("div");
  el11.className += " _coral-Slider-handle";
  el11.setAttribute("handle", "leftHandle");
  var el12 = document.createTextNode("\n    ");
  el11.appendChild(el12);
  var el13 = this["leftInput"] = document.createElement("input");
  el13.setAttribute("handle", "leftInput");
  el13.setAttribute("value", "1");
  el13.setAttribute("type", "range");
  el13.className += " _coral-Slider-input";
  el11.appendChild(el13);
  var el14 = document.createTextNode("\n  ");
  el11.appendChild(el14);
  el7.appendChild(el11);
  var el15 = document.createTextNode("\n  ");
  el7.appendChild(el15);
  var el16 = document.createElement("div");
  el16.className += " _coral-Slider-track";
  el7.appendChild(el16);
  var el17 = document.createTextNode("\n");
  el7.appendChild(el17);
  frag.appendChild(el7);
  var el18 = document.createTextNode("\n");
  frag.appendChild(el18);
  return frag;
});
export default template;