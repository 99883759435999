const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
var labelId = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["container"] = document.createElement("div");
  el2.setAttribute("handle", "container");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("label");
  el4.className += " u-coral-screenReaderOnly";
  el4.id = labelId;
  el4.textContent = data_0["i18n"]["get"]('Search Organizations');
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  var el6 = this["search"] = document.createElement("coral-search");
  el6.className += " _coral-Shell-orgSwitcher-search";
  el6.setAttribute("handle", "search");
  el6.setAttribute("placeholder", data_0["i18n"]["get"]('Search Organizations'));
  el6.setAttribute("labelledby", labelId);
  el2.appendChild(el6);
  var el7 = document.createTextNode("\n  ");
  el2.appendChild(el7);
  var el8 = this["items"] = document.createElement("div");
  el8.className += " _coral-Shell-orgSwitcher-items";
  el8.setAttribute("handle", "items");
  el2.appendChild(el8);
  var el9 = document.createTextNode("\n  ");
  el2.appendChild(el9);
  var el10 = this["resultMessage"] = document.createElement("div");
  el10.className += " _coral-Shell-orgSwitcher-resultMessage";
  el10.setAttribute("handle", "resultMessage");
  el10.setAttribute("hidden", "");
  var el11 = document.createTextNode("\n    ");
  el10.appendChild(el11);
  var el12 = document.createElement("div");
  el12.className += " _coral-Shell-orgSwitcher-resultMessage-container";
  var el13 = document.createTextNode("\n      ");
  el12.appendChild(el13);
  var el14 = document.createElement("h2");
  el14.className += " coral-Heading coral-Heading--2";
  el14.textContent = data_0["i18n"]["get"]('No organizations found.');
  el12.appendChild(el14);
  var el15 = document.createTextNode("\n    ");
  el12.appendChild(el15);
  el10.appendChild(el12);
  var el16 = document.createTextNode("\n  ");
  el10.appendChild(el16);
  el2.appendChild(el10);
  var el17 = document.createTextNode("\n");
  el2.appendChild(el17);
  frag.appendChild(el2);
  var el18 = document.createTextNode("\n");
  frag.appendChild(el18);
  return frag;
});
export default template;