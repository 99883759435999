const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["input"] = document.createElement("input");
  el0.setAttribute("type", "checkbox");
  el0.setAttribute("handle", "input");
  el0.className += " _coral-Checkbox-input";
  el0.id = data_0["commons"]["getUID"]();
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["checkbox"] = document.createElement("span");
  el2.className += " _coral-Checkbox-box";
  el2.setAttribute("handle", "checkbox");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  data = data_0;
  
    this.checkbox.innerHTML = data.Icon._renderSVG('spectrum-css-icon-CheckmarkSmall', ['_coral-Checkbox-checkmark', '_coral-UIIcon-CheckmarkSmall']);
    this.checkbox.innerHTML += data.Icon._renderSVG('spectrum-css-icon-DashSmall', ['_coral-Checkbox-partialCheckmark', '_coral-UIIcon-DashSmall']);
  
  data_0 = data;
  var el5 = document.createTextNode("\n");
  el2.appendChild(el5);
  frag.appendChild(el2);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["labelWrapper"] = document.createElement("label");
  el7.className += " _coral-Checkbox-label";
  el7.setAttribute("handle", "labelWrapper");
  el7.setAttribute("for", this["input"]["id"]);
  var el8 = document.createTextNode("\n  ");
  el7.appendChild(el8);
  var el9 = this["screenReaderOnly"] = document.createElement("span");
  el9.className += " u-coral-screenReaderOnly";
  el9.setAttribute("handle", "screenReaderOnly");
  el9.textContent = data_0["i18n"]["get"]('Select');
  el7.appendChild(el9);
  var el10 = document.createTextNode("\n");
  el7.appendChild(el10);
  frag.appendChild(el7);
  var el11 = document.createTextNode("\n");
  frag.appendChild(el11);
  return frag;
});
export default template;