const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["wrapper"] = document.createElement("div");
  el0.setAttribute("handle", "wrapper");
  el0.className += " _coral-Card-wrapper";
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  return frag;
});
export default template;