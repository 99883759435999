const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["checkIcon"] = document.createElement("span");
  el0.className += " _coral-SelectList-icon";
  el0.setAttribute("handle", "checkIcon");
  el0.setAttribute("hidden", "");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  data = data_0;
  
    this.checkIcon.innerHTML = data.Icon._renderSVG('spectrum-css-icon-CheckmarkMedium', ['_coral-UIIcon-CheckmarkMedium', '_coral-Menu-checkmark']);
  
  data_0 = data;
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;