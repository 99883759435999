const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["overlay"] = document.createElement("coral-popover");
  el0.setAttribute("withinoffset", "0");
  el0.setAttribute("smart", "");
  el0.id = data_0["commons"]["getUID"]();
  el0.className += " _coral-Autocomplete-overlay";
  el0.setAttribute("focusonshow", "off");
  el0.setAttribute("returnfocus", "off");
  el0.setAttribute("placement", "bottom");
  el0.setAttribute("handle", "overlay");
  el0.setAttribute("role", "presentation");
  el0.setAttribute("breadthoffset", "50%r - 50%p");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  data = data_0;
  
    // Constrains the size of the list to 6 items.
    // @todo move this to theme
    var maxHeight = 'max-height:' + (32 * 6) + 'px';
  
  data_0 = data;
  var el3 = document.createTextNode("\n  ");
  el0.appendChild(el3);
  var el4 = this["selectList"] = document.createElement("coral-buttonlist");
  el4.setAttribute("style", maxHeight);
  el4.id = data_0["commons"]["getUID"]();
  el4.className += " _coral-Autocomplete-selectList";
  el4.setAttribute("handle", "selectList");
  el4.setAttribute("role", "listbox");
  el4.setAttribute("interaction", "off");
  el0.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el0.appendChild(el5);
  frag.appendChild(el0);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  var el7 = this["field"] = document.createElement("input");
  el7.setAttribute("type", "hidden");
  el7.setAttribute("handle", "field");
  frag.appendChild(el7);
  var el8 = document.createTextNode("\n");
  frag.appendChild(el8);
  var el9 = this["inputGroup"] = document.createElement("div");
  el9.className += " _coral-InputGroup _coral-Autocomplete-inputGroup";
  el9.setAttribute("handle", "inputGroup");
  el9.setAttribute("role", "presentation");
  var el10 = document.createTextNode("\n  ");
  el9.appendChild(el10);
  var el11 = this["input"] = document.createElement("input","coral-textfield");
  el11.className += " _coral-InputGroup-field _coral-Autocomplete-input";
  el11.setAttribute("type", "text");
  el11.setAttribute("autocomplete", "off");
  el11.setAttribute("handle", "input");
  el11.setAttribute("role", "textbox");
  el11.setAttribute("is", "coral-textfield");
  el9.appendChild(el11);
  var el12 = document.createTextNode("\n  ");
  el9.appendChild(el12);
  var el13 = this["trigger"] = document.createElement("button","coral-button");
  el13.setAttribute("type", "button");
  el13.className += " _coral-FieldButton _coral-InputGroup-button _coral-Autocomplete-trigger";
  el13.setAttribute("is", "coral-button");
  el13.setAttribute("variant", "_custom");
  el13.setAttribute("handle", "trigger");
  el13.setAttribute("aria-label", data_0["i18n"]["get"]('Show suggestions'));
  el13.setAttribute("title", data_0["i18n"]["get"]('Show suggestions'));
  var el14 = document.createTextNode("\n    ");
  el13.appendChild(el14);
  var el15 = this["label"] = document.createElement("coral-button-label");
  el15.setAttribute("handle", "label");
  el13.appendChild(el15);
  var el16 = document.createTextNode("\n    ");
  el13.appendChild(el16);
  data = data_0;
  
      // Don't wait for button MO to pick up the label
      this.trigger._elements.label = this.label;
      // Render invalid icon
      this.trigger.insertAdjacentHTML('beforeend', data.Icon._renderSVG('spectrum-css-icon-ChevronDownMedium', ['_coral-InputGroup-icon', '_coral-UIIcon-ChevronDownMedium']));
    
  data_0 = data;
  var el18 = document.createTextNode("\n  ");
  el13.appendChild(el18);
  el9.appendChild(el13);
  var el19 = document.createTextNode("\n");
  el9.appendChild(el19);
  frag.appendChild(el9);
  var el20 = document.createTextNode("\n");
  frag.appendChild(el20);
  var el21 = this["tagList"] = document.createElement("coral-taglist");
  el21.className += " _coral-Autocomplete-tagList";
  el21.setAttribute("handle", "tagList");
  frag.appendChild(el21);
  var el22 = document.createTextNode("\n");
  frag.appendChild(el22);
  return frag;
});
export default template;