const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  var uid = data.commons.getUID();
  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["propertiesSubview"] = document.createElement("div");
  el2.className += " _coral-ColorInput-propertiesSubview";
  el2.setAttribute("handle", "propertiesSubview");
  el2.setAttribute("role", "group");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = document.createElement("div");
  el4.className += " _coral-ColorInput-previewView";
  el4.setAttribute("role", "presentation");
  var el5 = document.createTextNode("\n    ");
  el4.appendChild(el5);
  var el6 = document.createElement("div");
  el6.className += " _coral-ColorInput-preview-container";
  el6.setAttribute("role", "presentation");
  var el7 = document.createTextNode("\n      ");
  el6.appendChild(el7);
  var el8 = this["colorPreview2"] = document.createElement("button","coral-button");
  el8.setAttribute("is", "coral-button");
  el8.setAttribute("handle", "colorPreview2");
  el8.setAttribute("variant", "action");
  el8.className += " _coral-ColorInput-preview";
  el8.setAttribute("type", "button");
  el8.setAttribute("aria-pressed", "false");
  el6.appendChild(el8);
  var el9 = document.createTextNode("\n    ");
  el6.appendChild(el9);
  el4.appendChild(el6);
  var el10 = document.createTextNode("\n    ");
  el4.appendChild(el10);
  var el11 = document.createElement("div");
  el11.className += " _coral-ColorInput-editHex";
  el11.setAttribute("role", "presentation");
  var el12 = document.createTextNode("\n      ");
  el11.appendChild(el12);
  var el13 = document.createElement("label");
  el13.className += " _coral-ColorInput-editHex-label";
  var el14 = document.createTextNode("\n        ");
  el13.appendChild(el14);
  var el15 = document.createElement("span");
  el15.textContent = "#";
  el13.appendChild(el15);
  var el16 = document.createTextNode("\n        ");
  el13.appendChild(el16);
  var el17 = this["hexInput"] = document.createElement("input","coral-textfield");
  el17.className += " _coral-ColorInput-editHex-input";
  el17.setAttribute("type", "text");
  el17.setAttribute("handle", "hexInput");
  el17.setAttribute("is", "coral-textfield");
  el17.setAttribute("aria-label", data_0["i18n"]["get"]('HEX'));
  el17.setAttribute("placeholder", data_0["i18n"]["get"]('HEX'));
  el17.setAttribute("maxlength", "6");
  el17.setAttribute("value", "");
  el17.setAttribute("variant", "quiet");
  el13.appendChild(el17);
  var el18 = document.createTextNode("\n      ");
  el13.appendChild(el18);
  el11.appendChild(el13);
  var el19 = document.createTextNode("\n    ");
  el11.appendChild(el19);
  el4.appendChild(el11);
  var el20 = document.createTextNode("\n  ");
  el4.appendChild(el20);
  el2.appendChild(el4);
  var el21 = document.createTextNode("\n  ");
  el2.appendChild(el21);
  var el22 = document.createElement("div");
  el22.className += " _coral-ColorInput-rgbaView _coral-ColorInput-editRgba";
  el22.setAttribute("role", "presentation");
  var el23 = document.createTextNode("\n    ");
  el22.appendChild(el23);
  var el24 = document.createElement("div");
  el24.setAttribute("role", "group");
  el24.className += " _coral-ColorInput-editRgba-group";
  var el25 = document.createTextNode("\n      ");
  el24.appendChild(el25);
  var el26 = document.createElement("label");
  el26.className += " _coral-ColorInput-editRgba-group-label";
  el26.setAttribute("for", uid+"-r");
  el26.textContent = data_0["i18n"]["get"]('R');
  el24.appendChild(el26);
  var el27 = document.createTextNode("\n      ");
  el24.appendChild(el27);
  var el28 = this["redSlider"] = document.createElement("coral-colorinput-slider");
  el28.setAttribute("handle", "redSlider");
  el28.setAttribute("min", "0");
  el28.setAttribute("max", "255");
  el28.setAttribute("value", "0");
  el28.setAttribute("gradient", "#000000 #FF0000");
  el24.appendChild(el28);
  var el29 = document.createTextNode("\n      ");
  el24.appendChild(el29);
  var el30 = this["redInput"] = document.createElement("input","coral-textfield");
  el30.className += " _coral-ColorInput-editRgba-group-input";
  el30.id = uid+"-r";
  el30.setAttribute("handle", "redInput");
  el30.setAttribute("is", "coral-textfield");
  el30.setAttribute("type", "number");
  el30.setAttribute("placeholder", data_0["i18n"]["get"]('R'));
  el30.setAttribute("maxlength", "3");
  el30.setAttribute("value", "");
  el30.setAttribute("variant", "quiet");
  el24.appendChild(el30);
  var el31 = document.createTextNode("\n    ");
  el24.appendChild(el31);
  el22.appendChild(el24);
  var el32 = document.createTextNode("\n    ");
  el22.appendChild(el32);
  var el33 = document.createElement("div");
  el33.setAttribute("role", "group");
  el33.className += " _coral-ColorInput-editRgba-group";
  var el34 = document.createTextNode("\n      ");
  el33.appendChild(el34);
  var el35 = document.createElement("label");
  el35.className += " _coral-ColorInput-editRgba-group-label";
  el35.setAttribute("for", uid+"-g");
  el35.textContent = data_0["i18n"]["get"]('G');
  el33.appendChild(el35);
  var el36 = document.createTextNode("\n      ");
  el33.appendChild(el36);
  var el37 = this["greenSlider"] = document.createElement("coral-colorinput-slider");
  el37.setAttribute("handle", "greenSlider");
  el37.setAttribute("min", "0");
  el37.setAttribute("max", "255");
  el37.setAttribute("value", "0");
  el37.setAttribute("gradient", "#000000 #00FF00");
  el33.appendChild(el37);
  var el38 = document.createTextNode("\n      ");
  el33.appendChild(el38);
  var el39 = this["greenInput"] = document.createElement("input","coral-textfield");
  el39.className += " _coral-ColorInput-editRgba-group-input";
  el39.id = uid+"-g";
  el39.setAttribute("handle", "greenInput");
  el39.setAttribute("is", "coral-textfield");
  el39.setAttribute("type", "number");
  el39.setAttribute("placeholder", data_0["i18n"]["get"]('G'));
  el39.setAttribute("maxlength", "3");
  el39.setAttribute("value", "");
  el39.setAttribute("variant", "quiet");
  el33.appendChild(el39);
  var el40 = document.createTextNode("\n    ");
  el33.appendChild(el40);
  el22.appendChild(el33);
  var el41 = document.createTextNode("\n    ");
  el22.appendChild(el41);
  var el42 = document.createElement("div");
  el42.setAttribute("role", "group");
  el42.className += " _coral-ColorInput-editRgba-group";
  var el43 = document.createTextNode("\n      ");
  el42.appendChild(el43);
  var el44 = document.createElement("label");
  el44.className += " _coral-ColorInput-editRgba-group-label";
  el44.setAttribute("for", uid+"-b");
  el44.textContent = data_0["i18n"]["get"]('B');
  el42.appendChild(el44);
  var el45 = document.createTextNode("\n      ");
  el42.appendChild(el45);
  var el46 = this["blueSlider"] = document.createElement("coral-colorinput-slider");
  el46.setAttribute("handle", "blueSlider");
  el46.setAttribute("min", "0");
  el46.setAttribute("max", "255");
  el46.setAttribute("value", "0");
  el46.setAttribute("gradient", "#000000 #0000FF");
  el42.appendChild(el46);
  var el47 = document.createTextNode("\n      ");
  el42.appendChild(el47);
  var el48 = this["blueInput"] = document.createElement("input","coral-textfield");
  el48.className += " _coral-ColorInput-editRgba-group-input";
  el48.id = uid+"-b";
  el48.setAttribute("handle", "blueInput");
  el48.setAttribute("is", "coral-textfield");
  el48.setAttribute("type", "number");
  el48.setAttribute("placeholder", data_0["i18n"]["get"]('B'));
  el48.setAttribute("maxlength", "3");
  el48.setAttribute("value", "");
  el48.setAttribute("variant", "quiet");
  el42.appendChild(el48);
  var el49 = document.createTextNode("\n    ");
  el42.appendChild(el49);
  el22.appendChild(el42);
  var el50 = document.createTextNode("\n    ");
  el22.appendChild(el50);
  var el51 = document.createElement("div");
  el51.setAttribute("role", "group");
  el51.className += " _coral-ColorInput-editRgba-group";
  var el52 = document.createTextNode("\n      ");
  el51.appendChild(el52);
  var el53 = document.createElement("label");
  el53.className += " _coral-ColorInput-editRgba-group-label";
  el53.setAttribute("for", uid+"-a");
  el53.textContent = data_0["i18n"]["get"]('A');
  el51.appendChild(el53);
  var el54 = document.createTextNode("\n      ");
  el51.appendChild(el54);
  var el55 = this["alphaSlider"] = document.createElement("coral-colorinput-slider");
  el55.setAttribute("handle", "alphaSlider");
  el55.setAttribute("min", "0");
  el55.setAttribute("max", "100");
  el55.setAttribute("value", "100");
  el55.setAttribute("gradient", "rgba(255,255,255,0) rgba(255,255,255,1)");
  el51.appendChild(el55);
  var el56 = document.createTextNode("\n      ");
  el51.appendChild(el56);
  var el57 = this["alphaInput"] = document.createElement("input","coral-textfield");
  el57.className += " _coral-ColorInput-editRgba-group-input";
  el57.id = uid+"-a";
  el57.setAttribute("handle", "alphaInput");
  el57.setAttribute("is", "coral-textfield");
  el57.setAttribute("type", "number");
  el57.setAttribute("placeholder", data_0["i18n"]["get"]('A'));
  el57.setAttribute("maxlength", "3");
  el57.setAttribute("value", "");
  el57.setAttribute("variant", "quiet");
  el51.appendChild(el57);
  var el58 = document.createTextNode("\n    ");
  el51.appendChild(el58);
  el22.appendChild(el51);
  var el59 = document.createTextNode("\n  ");
  el22.appendChild(el59);
  el2.appendChild(el22);
  var el60 = document.createTextNode("\n");
  el2.appendChild(el60);
  frag.appendChild(el2);
  var el61 = document.createTextNode("\n");
  frag.appendChild(el61);
  return frag;
});
export default template;