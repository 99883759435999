const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  var el0 = this["checkmark"] = document.createElement("span");
  el0.setAttribute("handle", "checkmark");
  el0.setAttribute("hidden", "");
  el0.className += " _coral-Shell-orgSwitcher-checkmark";
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  data = data_0;
  
    this.checkmark.innerHTML = data.Icon._renderSVG('spectrum-css-icon-CheckmarkMedium', ['_coral-UIIcon-CheckmarkMedium', '_coral-Menu-checkmark']);
  
  data_0 = data;
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  var el5 = this["items"] = document.createElement("div");
  el5.setAttribute("handle", "items");
  el5.className += " _coral-Shell-orgSwitcher-subitems";
  frag.appendChild(el5);
  var el6 = document.createTextNode("\n\n");
  frag.appendChild(el6);
  return frag;
});
export default template;