const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["loadIndicator"] = document.createElement("div");
  el0.className += " _coral-SelectList-loading";
  el0.setAttribute("handle", "loadIndicator");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = document.createElement("coral-wait");
  el2.setAttribute("centered", "");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  return frag;
});
export default template;