const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["headerWrapper"] = document.createElement("div");
  el0.className += " _coral-Dialog-header";
  el0.setAttribute("handle", "headerWrapper");
  var el1 = document.createTextNode("\n  ");
  el0.appendChild(el1);
  var el2 = this["dragZone"] = document.createElement("div");
  el2.className += " _coral-Dialog-dragZone";
  el2.setAttribute("handle", "dragZone");
  el0.appendChild(el2);
  var el3 = document.createTextNode("\n");
  el0.appendChild(el3);
  frag.appendChild(el0);
  var el4 = document.createTextNode("\n");
  frag.appendChild(el4);
  var el5 = this["wrapper"] = document.createElement("div");
  el5.setAttribute("handle", "wrapper");
  var el6 = document.createTextNode("\n  ");
  el5.appendChild(el6);
  var el7 = this["closeButton"] = document.createElement("button","coral-button");
  el7.setAttribute("tracking", "off");
  el7.setAttribute("handle", "closeButton");
  el7.className += " _coral-Dialog-closeButton";
  el7.setAttribute("type", "button");
  el7.setAttribute("is", "coral-button");
  el7.setAttribute("variant", "quietaction");
  el7.setAttribute("title", data_0["i18n"]["get"]('Close'));
  el7.setAttribute("tabindex", "-1");
  el7.setAttribute("coral-close", "");
  el7.setAttribute("coral-tabcapture", "");
  var el8 = document.createTextNode("\n    ");
  el7.appendChild(el8);
  var el9 = document.createElement("coral-icon");
  el9.setAttribute("icon", "spectrum-css-icon-CrossLarge");
  el9.className += " _coral-UIIcon-CrossLarge";
  el7.appendChild(el9);
  var el10 = document.createTextNode("\n  ");
  el7.appendChild(el10);
  el5.appendChild(el7);
  var el11 = document.createTextNode("\n");
  el5.appendChild(el11);
  frag.appendChild(el5);
  var el12 = document.createTextNode("\n");
  frag.appendChild(el12);
  return frag;
});
export default template;