const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["topTabCapture"] = document.createElement("div");
  el0.setAttribute("handle", "topTabCapture");
  el0.setAttribute("coral-tabcapture", "top");
  el0.setAttribute("tabindex", "0");
  el0.setAttribute("role", "presentation");
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["intermediateTabCapture"] = document.createElement("div");
  el2.setAttribute("handle", "intermediateTabCapture");
  el2.setAttribute("coral-tabcapture", "intermediate");
  el2.setAttribute("tabindex", "0");
  el2.setAttribute("role", "presentation");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  var el4 = this["bottomTabCapture"] = document.createElement("div");
  el4.setAttribute("handle", "bottomTabCapture");
  el4.setAttribute("coral-tabcapture", "bottom");
  el4.setAttribute("tabindex", "0");
  el4.setAttribute("role", "presentation");
  frag.appendChild(el4);
  var el5 = document.createTextNode("\n");
  frag.appendChild(el5);
  return frag;
});
export default template;