const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["moreButton"] = document.createElement("button","coral-button");
  el0.setAttribute("tracking", "off");
  el0.className += " _coral-QuickActions-item _coral-QuickActions-item-more";
  el0.setAttribute("variant", "quietaction");
  el0.setAttribute("is", "coral-button");
  el0.setAttribute("type", "button");
  el0.setAttribute("icon", "more");
  el0.setAttribute("iconsize", "S");
  el0.setAttribute("handle", "moreButton");
  el0.setAttribute("title", data_0["i18n"]["get"]('More actions'));
  el0.setAttribute("role", "menuitem");
  el0.setAttribute("aria-haspopup", "true");
  el0.setAttribute("aria-label", data_0["i18n"]["get"]('More actions'));
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["overlay"] = document.createElement("coral-popover");
  el2.className += " _coral-QuickActions-moreOverlay";
  el2.setAttribute("tracking", "off");
  el2.setAttribute("smart", "");
  el2.id = data_0["commons"]["getUID"]();
  el2.setAttribute("breadthoffset", "50%p - 50%");
  el2.setAttribute("placement", "bottom");
  el2.setAttribute("handle", "overlay");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["buttonList"] = document.createElement("coral-buttonlist");
  el4.setAttribute("tracking", "off");
  el4.id = data_0["commons"]["getUID"]();
  el4.className += " _coral-QuickActions-buttonList";
  el4.setAttribute("handle", "buttonList");
  el4.setAttribute("tabindex", "-1");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n");
  el2.appendChild(el5);
  frag.appendChild(el2);
  var el6 = document.createTextNode("\n");
  frag.appendChild(el6);
  return frag;
});
export default template;