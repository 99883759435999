const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  var copyAttributes = data.copyAttributes;

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  if (data_0["items"]["isButtonList"]) {
    var el3 = document.createTextNode("\n  ");
    frag.appendChild(el3);
    var el4 = this["buttonList"] = document.createElement("coral-buttonlist");
    el4.className += " _coral-ActionBar-buttonList";
    el4.setAttribute("tabindex", "0");
    el4.setAttribute("coral-actionbar-offscreen", "");
    el4.setAttribute("handle", "buttonList");
    var el5 = document.createTextNode("\n    ");
    el4.appendChild(el5);
    var iterated_1 = data_0["items"];
    for (var i1 = 0, ni1 = iterated_1.length; i1 < ni1; i1++) {
      var data_1 = data = iterated_1[i1];
      var el7 = document.createTextNode("\n      ");
      el4.appendChild(el7);
      var el8 = this["buttonListItem"] = document.createElement("button","coral-buttonlist-item");
      el8.setAttribute("is", "coral-buttonlist-item");
      el8.setAttribute("handle", "buttonListItem");
      var el9 = document.createTextNode("\n        ");
      el8.appendChild(el9);
      data = data_1;
      
          copyAttributes(data._button, this.buttonListItem);
        
      data_1 = data;
      var el11 = document.createTextNode("\n        ");
      el8.appendChild(el11);
      var el12 = document.createElement("coral-list-item-content");
      var el13 = document.createTextNode("\n          ");
      el12.appendChild(el13);
      var el14 = this["buttonListItemContent"] = document.createElement("coral-action-bar-item");
      el14.setAttribute("coral-actionbar-offscreen", "");
      el14.setAttribute("handle", "buttonListItemContent");
      el12.appendChild(el14);
      var el15 = document.createTextNode("\n        ");
      el12.appendChild(el15);
      el8.appendChild(el12);
      var el16 = document.createTextNode("\n        ");
      el8.appendChild(el16);
      data = data_1;
      
          this.buttonListItemContent.innerHTML = data._button.label.innerHTML;
        
      data_1 = data;
      var el18 = document.createTextNode("\n      ");
      el8.appendChild(el18);
      el4.appendChild(el8);
      var el19 = document.createTextNode("\n      ");
      el4.appendChild(el19);
      if (data_1["_popover"]) {
        var el21 = document.createTextNode("\n        ");
        el4.appendChild(el21);
        data = data_1;
        
          var popover = data._popover.cloneNode(true);
          this.buttonList.items.add(popover);
        
        data_1 = data;
        var el23 = document.createTextNode("\n      ");
        el4.appendChild(el23);
      }
      var el24 = document.createTextNode("\n    ");
      el4.appendChild(el24);
    }
    var el25 = document.createTextNode("\n  ");
    el4.appendChild(el25);
    frag.appendChild(el4);
    var el26 = document.createTextNode("\n  ");
    frag.appendChild(el26);
    var el27 = document.createTextNode("\n");
    frag.appendChild(el27);
  }
  else {
    var el28 = document.createTextNode("\n    ");
    frag.appendChild(el28);
    if (data_0["items"]["isAnchorList"]) {
      var el30 = document.createTextNode("\n      ");
      frag.appendChild(el30);
      var el31 = this["anchorList"] = document.createElement("coral-anchorlist");
      el31.setAttribute("tabindex", "0");
      el31.setAttribute("coral-actionbar-offscreen", "");
      el31.setAttribute("handle", "anchorList");
      var el32 = document.createTextNode("\n        ");
      el31.appendChild(el32);
      var iterated_1 = data_0["items"];
      for (var i1 = 0, ni1 = iterated_1.length; i1 < ni1; i1++) {
        var data_1 = data = iterated_1[i1];
        var el34 = document.createTextNode("\n          ");
        el31.appendChild(el34);
        var el35 = this["anchorListItem"] = document.createElement("a","coral-anchorlist-item");
        el35.setAttribute("is", "coral-anchorlist-item");
        el35.setAttribute("handle", "anchorListItem");
        var el36 = document.createTextNode("\n            ");
        el35.appendChild(el36);
        data = data_1;
        
              copyAttributes(data._button, this.anchorListItem);
            
        data_1 = data;
        var el38 = document.createTextNode("\n            ");
        el35.appendChild(el38);
        var el39 = document.createElement("coral-list-item-content");
        var el40 = document.createTextNode("\n              ");
        el39.appendChild(el40);
        var el41 = this["anchorListItemContent"] = document.createElement("coral-action-bar-item");
        el41.setAttribute("coral-actionbar-offscreen", "");
        el41.setAttribute("handle", "anchorListItemContent");
        el39.appendChild(el41);
        var el42 = document.createTextNode("\n            ");
        el39.appendChild(el42);
        el35.appendChild(el39);
        var el43 = document.createTextNode("\n            ");
        el35.appendChild(el43);
        data = data_1;
        
              this.anchorListItemContent.innerHTML = data._button.label.innerHTML;
            
        data_1 = data;
        var el45 = document.createTextNode("\n          ");
        el35.appendChild(el45);
        el31.appendChild(el35);
        var el46 = document.createTextNode("\n          ");
        el31.appendChild(el46);
        if (data_1["_popover"]) {
          var el48 = document.createTextNode("\n            ");
          el31.appendChild(el48);
          data = data_1;
          
              var popover = data._popover.cloneNode(true);
              this.anchorList.items.add(popover);
            
          data_1 = data;
          var el50 = document.createTextNode("\n          ");
          el31.appendChild(el50);
        }
        var el51 = document.createTextNode("\n        ");
        el31.appendChild(el51);
      }
      var el52 = document.createTextNode("\n      ");
      el31.appendChild(el52);
      frag.appendChild(el31);
      var el53 = document.createTextNode("\n      ");
      frag.appendChild(el53);
      var el54 = document.createTextNode("\n    ");
      frag.appendChild(el54);
    }
    else {
      var el55 = document.createTextNode("\n        ");
      frag.appendChild(el55);
      var el56 = document.createElement("ul");
      el56.className += " _coral-ActionBar-list";
      var el57 = document.createTextNode("\n          ");
      el56.appendChild(el57);
      var iterated_1 = data_0["items"];
      for (var i1 = 0, ni1 = iterated_1.length; i1 < ni1; i1++) {
        var data_1 = data = iterated_1[i1];
        var el59 = document.createTextNode("\n            ");
        el56.appendChild(el59);
        var el60 = document.createElement("li");
        el60.className += " _coral-ActionBar-list-item";
        var el61 = document.createTextNode("\n              ");
        el60.appendChild(el61);
        el60.appendChild(data_1);
        var el63 = document.createTextNode("\n            ");
        el60.appendChild(el63);
        el56.appendChild(el60);
        var el64 = document.createTextNode("\n          ");
        el56.appendChild(el64);
      }
      var el65 = document.createTextNode("\n        ");
      el56.appendChild(el65);
      frag.appendChild(el56);
      var el66 = document.createTextNode("\n      ");
      frag.appendChild(el66);
    }
    var el67 = document.createTextNode("\n  ");
    frag.appendChild(el67);
  }
  var el68 = document.createTextNode("\n\n\n");
  frag.appendChild(el68);
  return frag;
});
export default template;