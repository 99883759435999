const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0;
  var el0 = this["liveRegion"] = document.createElement("div");
  el0.setAttribute("handle", "liveRegion");
  el0.className += " u-coral-screenReaderOnly _coral-Table-liveRegion";
  el0.setAttribute("aria-hidden", "true");
  el0.setAttribute("aria-live", "off");
  el0.id = data_0["commons"]["getUID"]();
  frag.appendChild(el0);
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["container"] = document.createElement("div");
  el2.setAttribute("handle", "container");
  el2.className += " _coral-Table-wrapper-container";
  el2.setAttribute("role", "presentation");
  el2.setAttribute("coral-table-scroll", "");
  var el3 = document.createTextNode("\n  ");
  el2.appendChild(el3);
  var el4 = this["hiddenStyle"] = document.createElement("style");
  el4.setAttribute("handle", "hiddenStyle");
  el2.appendChild(el4);
  var el5 = document.createTextNode("\n  ");
  el2.appendChild(el5);
  var el6 = this["alignmentStyle"] = document.createElement("style");
  el6.setAttribute("handle", "alignmentStyle");
  el2.appendChild(el6);
  var el7 = document.createTextNode("\n  ");
  el2.appendChild(el7);
  var el8 = this["table"] = document.createElement("table");
  el8.setAttribute("handle", "table");
  el8.className += " _coral-Table";
  el8.setAttribute("role", "grid");
  el8.setAttribute("aria-describedby", this["liveRegion"]["id"]);
  el2.appendChild(el8);
  var el9 = document.createTextNode("\n");
  el2.appendChild(el9);
  frag.appendChild(el2);
  var el10 = document.createTextNode("\n\n");
  frag.appendChild(el10);
  return frag;
});
export default template;