const template = (function anonymous(data_0
) {
  var frag = document.createDocumentFragment();
  var data = data_0 = typeof data_0 === "undefined" ? {} : data_0;
  data = data_0;
  
  const labelHourId = data.commons.getUID();
  const labelMinuteId = data.commons.getUID();

  data_0 = data;
  var el1 = document.createTextNode("\n");
  frag.appendChild(el1);
  var el2 = this["input"] = document.createElement("input");
  el2.setAttribute("type", "hidden");
  el2.setAttribute("name", "");
  el2.setAttribute("handle", "input");
  frag.appendChild(el2);
  var el3 = document.createTextNode("\n");
  frag.appendChild(el3);
  var el4 = this["valueAsText"] = document.createElement("time");
  el4.setAttribute("handle", "valueAsText");
  el4.id = data_0["commons"]["getUID"]();
  el4.setAttribute("hidden", "");
  frag.appendChild(el4);
  var el5 = document.createTextNode("\n");
  frag.appendChild(el5);
  var el6 = document.createElement("label");
  el6.setAttribute("for", labelHourId);
  el6.className += " coral-Form-fieldlabel";
  el6.textContent = data_0["i18n"]["get"]('Hours');
  frag.appendChild(el6);
  var el7 = document.createTextNode("\n");
  frag.appendChild(el7);
  var el8 = this["hours"] = document.createElement("input","coral-textfield");
  el8.setAttribute("is", "coral-textfield");
  el8.id = labelHourId;
  el8.setAttribute("type", "number");
  el8.setAttribute("min", "0");
  el8.setAttribute("max", "23");
  el8.className += " _coral-Clock-hour";
  el8.setAttribute("handle", "hours");
  frag.appendChild(el8);
  var el9 = document.createTextNode("\n");
  frag.appendChild(el9);
  var el10 = this["divider"] = document.createElement("span");
  el10.className += " _coral-Clock-divider";
  el10.setAttribute("handle", "divider");
  el10.textContent = ":";
  frag.appendChild(el10);
  var el11 = document.createTextNode("\n");
  frag.appendChild(el11);
  var el12 = document.createElement("label");
  el12.setAttribute("for", labelMinuteId);
  el12.className += " coral-Form-fieldlabel";
  el12.textContent = data_0["i18n"]["get"]('Minutes');
  frag.appendChild(el12);
  var el13 = document.createTextNode("\n");
  frag.appendChild(el13);
  var el14 = this["minutes"] = document.createElement("input","coral-textfield");
  el14.setAttribute("is", "coral-textfield");
  el14.id = labelMinuteId;
  el14.setAttribute("type", "number");
  el14.setAttribute("min", "0");
  el14.setAttribute("max", "59");
  el14.className += " _coral-Clock-minute";
  el14.setAttribute("handle", "minutes");
  frag.appendChild(el14);
  var el15 = document.createTextNode("\n");
  frag.appendChild(el15);
  var el16 = this["period"] = document.createElement("coral-select");
  el16.setAttribute("handle", "period");
  el16.className += " _coral-Clock-period";
  el16.setAttribute("hidden", "");
  el16.setAttribute("aria-label", data_0["i18n"]["get"]('AM/PM'));
  var el17 = document.createTextNode("\n  ");
  el16.appendChild(el17);
  var el18 = document.createElement("coral-select-item");
  el18.setAttribute("value", "am");
  el16.appendChild(el18);
  var el19 = document.createTextNode("\n  ");
  el16.appendChild(el19);
  var el20 = document.createElement("coral-select-item");
  el20.setAttribute("value", "pm");
  el16.appendChild(el20);
  var el21 = document.createTextNode("\n");
  el16.appendChild(el21);
  frag.appendChild(el16);
  var el22 = document.createTextNode("\n");
  frag.appendChild(el22);
  return frag;
});
export default template;